<template>
  <b-card
    class="contact-edit-wrapper"
  >
    <!-- form -->
    <b-form id="contactForm" class="edit-form mt-2">
      <b-row>
<!--        <b-col md="3">-->
<!--          <b-form-group-->
<!--            label-cols="3"-->
<!--            label-cols-lg="3"-->
<!--            label=""-->
<!--            label-for="company_id"-->
<!--            label-size="sm"-->
<!--            class="mb-1"-->
<!--          >-->
<!--            <b-form-input-->
<!--              id="company_id"-->
<!--              size="sm"-->
<!--              v-model="contact.company_id"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="姓名"
            label-for="name"
            label-size="sm"
            class="mb-1 required"
          >
            <b-form-input
              id="name"
              size="sm"
              v-model="contact.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="性别"
            label-for="sex"
            label-size="sm"
            class="mb-1 required"
          >
            <v-select
                id="sex"
                :options="getCodeOptions('sex')"
                :clearable="true"
                v-model="sex"
                @input="changeSelect('sex',$event)"
                class="select-size-sm"
                placeholder="请选择性别"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="职位"
                    label-for="position"
                    label-size="sm"
                    class="mb-1 required"
            >
                <v-select
                        id="position"
                        :options="getCodeOptions('company_position')"
                        :clearable="true"
                        v-model="contact.position"
                        class="select-size-sm"
                        placeholder="请选择职位"
                        :reduce="option => option.label"
                />
            </b-form-group>

        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="手机"
            label-for="mobile"
            label-size="sm"
            class="mb-1 required"
          >
            <b-form-input
              id="mobile"
              size="sm"
              v-model="contact.mobile"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="电话"
            label-for="phone"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="phone"
              size="sm"
              v-model="contact.phone"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="邮箱"
            label-for="email"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="email"
              size="sm"
              v-model="contact.email"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="地址"
            label-for="address"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="address"
              size="sm"
              v-model="contact.address"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="是否默认"
            label-for="is_default"
            label-size="sm"
            class="mb-1"
          >
            <b-form-checkbox
                name="check-button"
                switch
                inline
                id="receive_isoutlands"
                v-model="contact.is_default"
                value="1"
                unchecked-value="0"
            ></b-form-checkbox>
<!--            <b-form-input-->
<!--              id="is_default"-->
<!--              size="sm"-->
<!--              v-model="contact.is_default"-->
<!--            />-->
          </b-form-group>
        </b-col>
<!--        <b-col md="3">-->
<!--          <b-form-group-->
<!--            label-cols="3"-->
<!--            label-cols-lg="3"-->
<!--            label="启用"-->
<!--            label-for="state"-->
<!--            label-size="sm"-->
<!--            class="mb-1"-->
<!--          >-->
<!--            <b-form-input-->
<!--              id="state"-->
<!--              size="sm"-->
<!--              v-model="contact.state"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->
        <b-col md="12">
          <div class="inner-card-title">附件</div>
        </b-col>

        <b-col md="12">
          <b-form-group
            label-cols="1"
            label-cols-lg="1"
            label="附件"
            label-for="attachments"
            label-size="sm"
            class="mb-1"
          >
            <attachment-upload v-if="contact.loaded"
                               :attachment_id="'attachments'"
                               :id="contact.attachments"
                               :object_type="'test'"
                               :object_id="0"
                               @change="onUploaded"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            返回
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import contactStore from './contactStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor,second } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import companyStore from "@/views/apps/company/companyStore";


export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    AttachmentUpload,
  },
  data() {
    return {
      id: ref(0),
      contact: ref({}),
      companyId:0,
      sex:{},
      toReturnBack:0,
    }
  },
  props:{
    company_id:{
      type:Number,
      default:0,
    }
  },
  setup(props) {

    const toast = useToast()

    // Register module
    if (!store.hasModule('contact')) store.registerModule('contact', contactStore)
    if (!store.hasModule('company')) store.registerModule('company', companyStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('contact')) store.unregisterModule('contact')
      if (store.hasModule('company')) store.unregisterModule('company')
    })

    const edit = function() {
      store.dispatch('contact/edit', {id: this.id}).then(res => {
        this.contact = res.data.data
        this.contact.state =1
        this.sex = getCode("sex",this.contact.sex)
      })
    }

    const view = function() {
      store.dispatch('contact/view', {id: this.id}).then(res => {
        this.contact = res.data.data
      })
    }

    const cancel = function() {
      this.$router.push({name: 'apps-company-supplier-edit', query: {id:this.companyId}});
      //this.$router.go(-1)
    }

    const save = function() {
      if (this.companyId==0){
        this.contact.company_id = this.company_id
      }
      if (this.company_id==0){
        this.contact.company_id = this.companyId
      }
      // this.contact.company_id = this.companyId
      if (this.contact.name===undefined||this.contact.name===""){
        toast.error("请填写姓名!")
        return false
      }
      if (this.contact.sex===undefined||this.contact.name===""){
        toast.error("请填写性别!")
        return false
      }
      if (this.contact.mobile===undefined||this.contact.mobile===""){
        toast.error("请填写手机!")
        return false
      }
      if (this.contact.position===undefined||this.contact.position===""){
        toast.error("请填写职位!")
        return false
      }

      if (this.contact.is_default===undefined||this.contact.is_default===""){
        this.contact.is_default=0
      }
      store.dispatch('contact/save', this.contact).then(res => {
        if (res.data.code==0){
          toast.success('联系人数据已保存!')
          // this.contact = res.data.data
          if (this.companyId!=0){
            // this.$router.push({ name: 'apps-contact-list',query:{id:this.companyId}});
          }
        }else {
          toast.error(res.data.data)
        }


      })
    }

    const changeSelect = function(key,event) {
      this.contact[key] = event.value
    }

    const onUploaded = function (id, attachment) {
      this.contact[id] = attachment.attachment_id
    }

    return {
      edit,
      view,
      cancel,
      save,

      toTime,
      toDate,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      second,

      changeSelect,
      onUploaded,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.companyId = this.$route.query.companyId || 0;
    this.toReturnBack = this.$route.query.toReturnBack || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
